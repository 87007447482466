.round-cycle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

.processing {
  composes: round-cycle;
  opacity: 0.2;
}

.updated {
  composes: round-cycle;
  @apply bg-success-7;
}

.outdated {
  composes: round-cycle;
  @apply bg-error-8;
}

.unknown {
  composes: round-cycle;
  @apply bg-gray-5;
}
