/* Overide Vendor CSS */
.form-control {
  border: 1px solid var(--border-form-control-color);
  background-color: var(--bg-inputbox);
  color: var(--text-form-control-color);
}

.text-muted {
  color: var(--text-muted-color);
}

.table > thead > tr > th {
  border-bottom: 1px solid var(--border-table-color);
}

.table-hover > tbody > tr:hover {
  background-color: var(--bg-hover-table-color);
}

.switch i {
  background: var(--bg-switch-box-color);
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  border-top: 1px solid var(--border-table-top-color);
}

.input-group-addon {
  color: var(--text-input-group-addon-color);
  background-color: var(--bg-input-group-addon-color);
  border: 1px solid var(--border-input-group-addon-color);
}

.input-group .form-control {
  z-index: unset;
}

.text-danger {
  color: var(--ui-error-9);
}

.table .table {
  background-color: initial;
}

.table-bordered {
  border-color: var(--border-table-top-color);
}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border-color: var(--border-table-top-color);
}

.md-checkbox input[type='checkbox']:disabled + label:before {
  background: var(--bg-md-checkbox-color) !important;
  border-color: var(--border-md-checkbox-color) !important;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: var(--bg-form-control-disabled-color) !important;
}

.modal.in .modal-dialog {
  border: var(--border-modal);
}

.modal-content {
  background-color: var(--bg-modal-content-color);
}

code {
  color: var(--text-code-color);
  background-color: var(--bg-code-color);
}

.nav-container {
  border: 1px solid var(--border-nav-container-color);
  background-color: var(--bg-nav-container-color);
  border-radius: 8px;
  padding: 10px;
}

.nav-tabs {
  border-bottom: 1px solid var(--border-navtabs-color);
}

.nav-tabs > li {
  background-color: var(--bg-nav-tabs-active-color);
  border-top-right-radius: 8px;
}

.nav-tabs > li.active > a {
  border: 1px solid transparent;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: var(--text-nav-tab-active-color);
  background-color: var(--bg-nav-tab-active-color);
  border: 1px solid var(--border-navtabs-color);
}

.nav-tabs > li.disabled > a {
  border-color: transparent;
  pointer-events: none;
}

.nav-tabs > li > a:hover {
  border-color: var(--border-navtabs-color);
}

.nav > li > a {
  color: var(--text-navtabs-color);
}

.nav > li > a:hover,
.nav > li > a:focus {
  color: var(--text-navtabs-hover-color);
  background-color: var(--bg-navtabs-hover-color);
}

.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active > td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th {
  background-color: var(--bg-table-selected-color);
}
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active,
.table-hover > tbody > tr.active:hover > th {
  background-color: var(--bg-table-selected-color);
}

.dropdown-menu {
  background: var(--bg-dropdown-menu-color);
  border-radius: 8px;
}

.dropdown-menu > li > a {
  color: var(--text-dropdown-menu-color);
}

pre {
  border: 1px solid var(--border-pre-color);
  border-radius: 8px;
  background-color: var(--bg-pre-color);
  color: var(--text-pre-color);
}
json-tree .key {
  color: var(--text-json-tree-color);
}

json-tree .leaf-value {
  color: var(--text-json-tree-leaf-color);
}

json-tree .branch-preview {
  color: var(--text-json-tree-branch-preview-color);
}

.progress {
  background-color: var(--bg-progress-color);
}

.motd-body {
  background-color: var(--bg-motd-body-color) !important;
}

.panel-body {
  background-color: var(--bg-panel-body-color) !important;
}

.panel {
  border: 1px solid var(--border-panel-color);
  background-color: var(--bg-panel-body-color);
  border-radius: 8px;
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
}

.theme-information .col-sm-12 {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 15px;
}

.theme-panel {
  margin-top: 15px;
}

.bold {
  color: var(--text-summary-color);
  font-weight: 700;
}

.input-sm {
  background-color: var(--bg-input-sm-color);
  border: 1px solid var(--border-input-sm-color);
}

.rzslider .rz-bubble {
  color: var(--text-rzslider-color);
}

.rzslider .rz-bubble.rz-limit {
  color: var(--text-rzslider-limit-color);
}

.rz-bubble.rz-limit.rz-ceil {
  position: absolute;
  right: 0;
  left: auto !important;
  top: -26px;
}

input,
select,
textarea {
  background: var(--text-input-textarea);
}

.daterangepicker {
  background-color: var(--bg-daterangepicker-color);
  border: 1px solid var(--border-daterangepicker-color);
}

.daterangepicker .drp-calendar.left {
  background: var(--bg-calendar-color);
}

.daterangepicker .drp-calendar.left .calendar-table {
  background: var(--bg-calendar-table-color);
}

.daterangepicker .drp-calendar.right {
  background: var(--bg-calendar-color);
}

.daterangepicker .drp-calendar.right .calendar-table {
  background: var(--bg-calendar-table-color);
}

.daterangepicker .calendar-table {
  border: 1px solid var(--border-calendar-table);
}

.daterangepicker td.off,
.daterangepicker td.off.in-range,
.daterangepicker td.off.start-date,
.daterangepicker td.off.end-date {
  background-color: var(--bg-daterangepicker-end-date);
  color: var(--text-daterangepicker-end-date);
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: var(--bg-daterangepicker-hover);
}

.daterangepicker td.in-range {
  background-color: var(--bg-daterangepicker-in-range);
  color: var(--text-daterangepicker-in-range);
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--bg-daterangepicker-active);
  color: var(--text-daterangepicker-active);
}

.daterangepicker .drp-buttons {
  border-top: 1px solid var(--border-daterangepicker);
}

.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  border-color: var(--border-pre-next-month);
}

.daterangepicker:after {
  border-bottom: 6px solid var(--border-daterangepicker-after);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--bg-input-autofill-color) inset !important;
  box-shadow: 0 0 0 30px var(--bg-input-autofill-color) inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: var(--text-input-autofill-color) !important;
}

/* Overide Vendor CSS */

.multiSelect.inlineBlock button {
  margin: 0;
}

.label-default {
  line-height: 11px;
  vertical-align: middle;
}

/* Code Script Style */
.code-script {
  background-color: var(--bg-code-script-color);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 5px;
}

/* Tippy */
/* For triangle arrow (Sharp) */
.tippy-box[data-placement^='top'] > .tippy-arrow:before {
  border-top: 8px solid var(--bg-tooltip-color);
}

.tippy-box[data-placement^='bottom'] > .tippy-arrow:before {
  border-bottom: 8px solid var(--bg-tooltip-color);
}

.tippy-box[data-placement^='right'] > .tippy-arrow:before {
  border-right: 8px solid var(--bg-tooltip-color);
}

.tippy-box[data-placement^='left'] > .tippy-arrow:before {
  border-left: 8px solid var(--bg-tooltip-color);
}

/* Sidebar */
.sidebar .tippy-box {
  font-size: 12px;
}

.sidebar .tippy-box[data-placement^='right'] > .tippy-arrow {
  width: 12px;
  height: 12px;
}

.sidebar .tippy-box[data-placement^='right'] > .tippy-arrow:before {
  border-right: 8px solid var(--ui-gray-9);
  border-width: 6px 8px 6px 0;
}

[theme='dark'] .sidebar .tippy-box[data-placement^='right'] > .tippy-arrow:before {
  border-right: 8px solid var(--ui-gray-true-9);
}

.sidebar .tippy-content {
  white-space: nowrap;
}

.tippy-content {
  padding: 0;
}
